import React from 'react'

import Layout from '../../components/Layout'

import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import RegisterHero from "../../img/register/registerHero.png";

const useStyles = makeStyles(theme => ({
    fullContainer: {
        backgroundColor: "#f1f1f1",
        minHeight: "90vh",
    },
    narrowContainer: {
        minHeight: "90vh",
        paddingTop: "1rem",
        paddingBottom: "1rem",        
    },
    heroRight: {
        backgroundOrigin: "content-box",
        background: `url(${RegisterHero})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%",
        backgroundPosition: "center right",
    }
    
}));


export const RegisterThanksPageTemplate = () => {

    const classes = useStyles();
    
    return (
        <Container maxWidth={false} className={classes.fullContainer}>
            <Container>
                <Grid container className={classes.narrowContainer}>
                    <Grid item container xs={12} md={6} direction="column" alignItems="center" justify="center">
                        <Typography variant="h2" component="p" align='left' style={{color: "#262626", paddingBottom: "1rem"}}>
                            Thanks!        
                        </Typography>
                        <Typography variant="body1" component="p" align='left' style={{color: "#262626", paddingBottom: "1rem"}}>
                            Thanks for registering for early access. We'll be in touch with more information about DanceClarity over the upcoming months!
                        </Typography>

                    </Grid>
                    <Grid item xs={12} md={6} className={classes.heroRight}>

                    </Grid>
                    
                </Grid>
            </Container>
        </Container>
    )
}

const RegisterThanksPage = () => {
    return (
        <Layout>
            <RegisterThanksPageTemplate/>
        </Layout>
    )
}

export default RegisterThanksPage;